.loginForm {
  padding: 60px 40px 60px 40px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  width: 580px
}

@media(max-width: 1400px) {
  .loginForm {
    width: 450px;
  }
}

.resetPasswordButton {
  text-align: right;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
}

.marginRight {
  width: auto;
  margin: auto 15px auto auto;
}