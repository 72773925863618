.ant-table-column-title {
  z-index: 0;
}

.table-with-clickable-row {
  .ant-table-cell {
    cursor: pointer;
  }

  table tr:nth-child(2n) td, table tr:nth-child(2n):hover td
  {
    background-color: #F2F2F1;
  }
}


