$footer-height: 80px;

.footer {
  padding: 0;
  min-height: $footer-height;

  font-size: 14px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .logo {
    height: $footer-height;
  }

  .link,
  .link:visited {
    color: white;
    white-space: nowrap;
    margin: 0 16px;
    padding: 30px;
  }
}

// logo width + 1 link width
@media (max-width: 380px) {
  .footer {
    flex-direction: column;
    align-items: stretch;

    .link {
      width: 100%;
      text-align: center;
      margin: 8px 0;
    }
  }
}
