.centeredContent {
  text-align: center;
}

.shortNewsTitle {
  font-size: 20px;
}

.text {
  white-space: pre-line;
  text-align: justify;
  font-size: 16px;
}

.hiddenText {
  text-overflow: ellipsis;
  overflow: hidden;
  height: 150px;
}


.container, .darkContainer {
  padding: 25px 15px;
}

.darkContainer {
  background-color: #f1eeec;
}

.title {
  font-weight: 700;
  color: #516069;
  font-size: 16px;
  margin-bottom: 5px;
  white-space: pre-wrap;
  text-align: justify;
}

.link {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1.2px;
  text-decoration: underline;
}

.trashIconContainer {
  display: flex;
}

.clickableIcon {
  font-size: 24px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  margin-top: 10px;
}

.plusIcon {
  color: #7A6855;
  font-size: 16px;
  margin-left: 15px;
}

.addButton {
  margin-bottom: 30px;
}

.rssFeedInput {
  margin-bottom: 15px;
}

.button {
  margin-top: 10px;
}

.flagIcon {
  margin-right: 5px;
  margin-bottom: 2px;
}

.icon {
  margin-right: 10px;
  align-self: center;
}

.preview {
  border: 1px solid #80776c;
  padding: 10px;
}