// FORM
@form-item-margin-bottom: 50px;
@form-vertical-label-padding-bottom: 24px;
@form-vertical-label-padding: 0 0 @form-vertical-label-padding-bottom;

.ant-form-item-explain.ant-form-item-explain-error {
  padding-bottom: 18px;
}

.ant-form-vertical .ant-form-item-label {
  padding-bottom: 10px;
}

.ant-form-item-label label {
  font-weight: 600;
}

// INPUTS
@input-height-base: 40px;
@input-height-lg: 62px;
@select-single-item-height-lg: @input-height-lg;

@input-padding-horizontal-lg: 24px;
@input-padding-vertical-lg: 16px;

.ant-input-number {
  width: 250px;

  .ant-input-number-input {
    text-align: right;
    // more padding so display is not hidden by arrow +/-
    padding-right: 33px;
  }
}

.ant-radio-button-wrapper {
  margin: 6px;
}

.ant-select-item-option-content {
  color: #746250;
}

.startup-form, .user-invite-form {
  .ant-checkbox-wrapper, .ant-radio-wrapper {
    padding: 15px 25px;
    border: 1px solid #7A6855;
    color: #7A6855;
  }

  .ant-checkbox-wrapper-checked, .ant-radio-wrapper-checked {
    background-color: #cfb07b;
    border-color: #cfb07b;
    color: white;
  }

  .ant-checkbox-inner, .ant-radio-inner {
    border-color: #7A6855;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #cfb07b;
    padding: 5px;
    border-color: white;
  }

  .ant-radio-checked .ant-radio-inner {
    background-color: white;
    padding: 5px;
    border-color: white;
  }

  .ant-checkbox-checked::after {
    border-color: white;
  }

  .ant-radio-inner::after {
    background-color: #cfb07b;
  }

  .ant-checkbox-wrapper-checked:hover .ant-checkbox-inner,
  .ant-checkbox-checked:hover .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner
  {
    border-color: white;
  }

  .ant-radio-wrapper-checked:hover .ant-radio-inner,
  .ant-radio-checked:hover .ant-radio-inner,
  .ant-radio-checked .ant-radio-input:focus + .ant-radio-inner
  {
    background-color: white;
  }
}

.ant-input::placeholder {
  color: #71787A;
}

.ant-upload.ant-upload-select {
  text-align: center;
  display: block;
}