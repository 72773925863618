.blocText {
  font-size: 16px;
  line-height: 28px;
}

.titleMargin {
  margin-top: 82px;
}

.link {
  padding: unset;
}

.button {
  width: 100%;
}
