.clickableIcon {
  font-size: 24px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  margin-top: 10px;
}

.iconsContainer {
  display: flex;
  flex-direction: column;
}

.imageContainer {
  display: flex;
  justify-content: center;
  border: 1px solid #DEDAD4;
}