.h100 {
  height: 100%;
}

.centeredContent {
  display: flex;
  align-items: center;
}

.margins {
  margin-left: auto;
  margin-right: auto;
}

.buttonIcon {
  cursor: pointer;
}

.smallImage {
  margin-bottom: 10px;
  max-height: 100%;
  width: -moz-available;
  cursor: pointer;

  @media screen and (max-width: 1400px) {
    max-width: 250px !important;
  }

  @media screen and (min-width: 1400px) {
    max-width: 400px !important;
  }

}

.bigImage {
  max-width: 100%;
  max-height: 100%;
}

.imageContainer {
  padding: 2px;
}

.avatarDisplay {
  width: 128px;
  height: 128px;

  .avatarImage {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
    cursor: pointer;
  }
}
