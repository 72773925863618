.Bloc {
  display: flex;

  .BlocIcon {
    width: 40px;
    height: 40px;
    margin-right: 30px;
  }

  .description {
    white-space: pre;
  }

}

.welcomeContainer {
  margin-bottom: 30px;
}

.subTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 1.2px;
}

.blocTitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 1.2px;
}