.avatarDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 128px;
  height: 128px;

  .avatarImage {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
    cursor: pointer;
  }
}

.buttonContainer {
  padding: 0;
  white-space: pre;
}

.plusIcon {
  border: 1px dashed #DEDAD4;
  padding: 15px;
  color: #CFB07B;
}