.collapse {
  background-color: transparent;
}

.collapsePanel {
  padding-bottom: 12px;
  margin-bottom: 24px;
}

.collapsePanelHeader {
  &,
  &Active {
    color: #2a2c30;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
  }

  &Active {
    font-weight: bold;
  }
}
