$SectionTabs: ':global(.ant-tabs-card.ant-tabs-top).SectionTabs';

#{$SectionTabs} > :global(.ant-tabs-nav) {
  margin: 0 !important;
  justify-content: center;
  // mimic row padding like Section component
  padding: 0 20px;

  &::before {
    border-bottom: 0;
  }

  :global {
    .ant-tabs-tab {
      background-color: white;
      border-color: #e1ddda;
      border-bottom: 0;
      padding: 22px 33px;

      & .ant-tabs-tab-btn,
      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        text-transform: uppercase;
        color: #2a2c30;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 3px;
        line-height: 17px;
      }

      &:not(:last-of-type) {
        margin-right: 16px;
      }

      &.ant-tabs-tab-active {
        background-color: #f1eeec;
        border-color: #f1eeec;
      }
    }
  }
}

#{$SectionTabs} > :global(.ant-tabs-content-holder) {
  border-top: 1px solid #f1eeec;
}

$SectionTabsNavWrap: '#{$SectionTabs} :global(.ant-tabs-nav-wrap)';

// XS: 24
@media (max-width: 575.98px) {
  #{$SectionTabsNavWrap} {
    // mimic col width like Section component
    max-width: 100%;
  }
}

// SM: 22
@media (min-width: 576px) and (max-width: 767.98px) {
  #{$SectionTabsNavWrap} {
    // mimic col width like Section component
    max-width: calc(22 / 24) * 100%;
  }
}

// MD +: 20
@media (min-width: 768px) {
  #{$SectionTabsNavWrap} {
    // mimic col width like Section component
    max-width: calc(20 / 24) * 100%;
  }
}
