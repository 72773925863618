.clickableIcon {
  font-size: 24px;
  cursor: pointer;
}

.clickableDescription {
  margin-left: 10px;
  cursor: pointer;
}

.addBtnSpacing {
  margin-bottom: 20px;
}

// more than xs
@media (min-width: 576px) {
  .DrawerFooter {
    button {
      margin-right: 20px;
    }
  }
}

// xs
@media (max-width: 576px) {
  .DrawerFooter {
    button {
      width: 100%;
      padding: 17px 0 !important;
    }

    button + button {
      margin-top: 8px;
    }
  }
}
