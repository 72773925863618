.browser {
  text-align: center;
  width: 60px;

  &Icon {
    width: auto;
    height: 40px;
  }

  &Name {
    font-size: 0.8em;
  }
}

.container {
  text-align: center;
  // For internet explorer, do not remove :)
  width: 100%;
}
