$dot-size: 18px;
$active-dot-size: 40px;
$border-size: 3px;

$done-color: #cfb07b;
$todo-color: #746250;

.Steps {
  display: flex;
  align-items: center;
  margin: 17px 0 37px 0;
}

.StepsItemTail,
.StepsTail {
  width: 40px;
  height: 1px;
  background: #dfd7d0;

  .StepsItemDotDoing + & {
    width: 29px;
  }
}

.StepsTail {
  flex-grow: 1;
}

.StepsItemDot {
  width: $dot-size;
  height: $dot-size;
  border-radius: 100px;
  background-color: $todo-color;
  border: $border-size solid #f8f6f5;

  &.StepsItemDotDone {
    background-color: $done-color;
  }

  &.StepsItemDotDoing {
    width: $active-dot-size;
    height: $active-dot-size;
    line-height: $active-dot-size - 2 * $border-size;
    background-color: $done-color;
    color: white;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-left: -11px;
  }
}
