.bandeau {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 46px;

  font-size: 16px;
  font-weight: 600;
  line-height: 28px;

  background-color: #746250;
  color: white;

  border-bottom: 1px solid #dfd7d0;

  &.info {
    background-color: #f1eeec;
    color: #2a2c30;
  }

  &.warning,
  &.error {
    background-color: #953d51;
    color: white;
  }

  &Icon {
    font-size: 1.6em;
    margin-right: 16px;
  }
}
