@media only screen and (max-width: 1400px) {
  .startup_card.ant-col-xl-6 {
    display: block;
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    max-width: 33%;
  }
}

@media only screen and (max-width: 1100px) {
  .startup_card.ant-col-xl-6 {
    display: block;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 650px) {
  .startup_card.ant-col-xl-6 {
    display: block;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
