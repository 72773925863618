.FilterBloc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;

  & + & {
    margin-top: 15px;
  }
}

// more than xs
@media (min-width: 576px) {
  .DrawerFooter {
    button {
      margin-right: 8px;
    }
  }
}

// xs
@media (max-width: 576px) {
  .DrawerFooter {
    button {
      width: 100%;
      padding: 17px 0 !important;
    }

    button + button {
      margin-top: 8px;
    }
  }
}