@tooltip-max-width: 250px;
@tooltip-color: #2a2c30;
@tooltip-bg: #f1eeec;
@tooltip-arrow-width: 15px;

.ant-tooltip {
  .ant-tooltip-arrow-content {
    box-shadow: none;
  }

  .ant-tooltip-inner {
    padding: 24px;
    box-shadow: none;
    font-size: 12px;
    line-height: 18px;

    ul {
      padding-inline-start: 14px;
    }
  }
}
// xs et sm
@media (max-width: @screen-md) {
  .ant-tooltip {
    max-width: 80vw;
  }
}
