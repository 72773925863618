@import 'styles/vendor';
@import 'styles/list';

@defaultFonts: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 20px;
}

h1 {
  font-family: OpenSans, @defaultFonts;
  font-weight: 400;
  color: #000000;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 1.2px;
}

h2 {
  color: @primary-color;
  font-size: 34px;
  letter-spacing: 1.2px;
  line-height: 30px;
  text-transform: uppercase;

  small {
    color: @text-color;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    white-space: nowrap;
    text-transform: none;
  }
}

h3 {
  color: @primary-color;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 33px;
}

h4 {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;
}

.text-align-center {
  text-align: center;
}

.text-align-justify {
  text-align: justify;
}

.flex-none {
  flex: none;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

label small {
  color: @primary-color;
  margin-left: 12px;
  font-weight: normal;
}

p {
  white-space: pre-wrap;
}

@media print {
  .no-print {
    display: none !important;
  }

  .no-print-nav > .ant-tabs-nav {
    display: none !important;
  }

  abbr[title]:after {
    content: ' (' attr(title) ')';
    white-space: break-spaces;
  }

  a[href]:not(a[href^='mailto:']):not(a[href^='tel:']):after {
    content: ' (' attr(href) ')';
    white-space: break-spaces;
  }
}

.search-bar-container {
  display: flex;
  flex-direction: column;
}

.search-label {
  font-size: 13px;
  color: black;
  font-weight: 600;
  margin-bottom: 10px;
  margin-left: 2px;
}