.header {
  border-bottom: 1px solid #dfd7d0;
  display: flex;
  justify-content: flex-start;
}

$header-height: 110px;
$logo-height: 65px;

.logo {
  height: $logo-height;
  width: auto;
  margin: calc(($header-height - $logo-height) / 3);
  cursor: pointer;
}

.menu {
  border-bottom: unset;
  flex-grow: 1;
}

.pullRight {
  float: right;
}

.headerInline {
  display: inline;
}
