$headerHeight: 90px;
$paddingHeight: 16px;

.header {
  border-bottom: 1px solid #dfd7d0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $paddingHeight 24px;
  width: 100%;

  &.headerOpen {
    position: fixed;
    z-index: 1;
  }
}

.logo {
  width: auto;
  height: $headerHeight - 2 * $paddingHeight;
  cursor: pointer;
}

.link {
  color: #2a2c30;
  font-size: 16px;
  letter-spacing: 1.4px;
  line-height: 24px;

  text-transform: uppercase;
}

.logout {
  color: #746250;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 3px;
  line-height: 19px;
}

.divider {
  background-color: #2a2c30;
}

.mask {
  position: fixed;
  z-index: 1;
  height: calc(100vh - #{$headerHeight});
  width: 100vw;
  top: $headerHeight;

  &Body {
    width: 100%;
  }
}
