@import '~antd/dist/antd.less';
// default values in antd/lib/style/themes/default.less

@font-family: 'OpenSans';
@text-color: #2a2c30;
@heading-color: @text-color;
@primary-color: #746250;
@secondary-color: #cfb07b;
@grey-color: rgb(107, 128, 135);
@framboise-color: rgb(149, 61, 81);

@font-size-base: 14px;

@layout-header-height: 95px;
@layout-header-padding: 0;
@layout-header-background: white;
.ant-layout-header {
  height: unset;
  line-height: @layout-header-height - @menu-item-border-bottom-width;
}

@layout-footer-background: #1d1f24;

@border-radius-base: 0;
@border-color-split: #dfd7d0;

@import './components/_menu';
@import './components/_table';
@import './components/_drawer';
@import './components/_button';
@import './components/_steps';
@import './components/_modal';
@import './components/_form';
@import './components/_tooltip';
@import './components/_grid';

// DIVIDER
.ant-divider {
  color: #d8d8d8;

  &-horizontal {
    margin: 20px 0;
  }
}

// OTHERS TODO cleanup ?
.ant-card {
  box-shadow: 0 0 31px 0 rgba(118, 104, 81, 0.1);
}

.ant-icon-primary {
  color: @primary-color;
}
