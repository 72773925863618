.subTitle {
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: 1.2px;
  color: #746250;
  margin-top: 25px;
  margin-bottom: 25px;
}

.badgeEventStatus {
  color: white;
  padding: 5px;
  width: 36px;
  height: 36px;
  display: flex;
}

.connectionRequestsText {
  color: #746250;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 1.2px;

  .numberOfConnectionRequests {
    font-weight: 700;
    font-size: 24px;
    margin-left: 5px;
  }
}

.viewedStartups {
  .startupCard {
    background-color: white;
    padding: 24px;
    height: 120px;
  }

  @media (max-width: 991px) {
    .startupCard {
      padding: 16px;
    }
  }

  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F2F2F1;
    width: 72px;
    height: 72px;
    padding: 5px;
  }

  .startupLogo {
    width: 72px;
  }

  .startupName {
    display: flex;
    align-items: center;
    color: #746250;
    font-weight: 600;
    font-size: 22px;
  }
}

.marginBottom {
  margin-bottom: 10px;
}

.alignCenter {
  align-self: center;
}

.divider {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 1200px) {
  .marginBetweenBadges:not(:last-child) {
    margin-bottom: 10px;
  }

}