// <span class="ant-badge ant-badge-status ant-badge-not-a-wrapper">
//    <span class="ant-badge-status-dot ant-badge-status-default" />
//    <span class="ant-badge-status-text">{text}</span>
// </span>

// fix alignement inside h2
:global(h2) > .badge {
  vertical-align: bottom;
}

.badge {
  :global {
    border-radius: 15.5px;
    background-color: #953d51;

    padding: 0 16px;

    .ant-badge-status-dot {
      background-color: white;
    }

    .ant-badge-status-text {
      color: white;
      line-height: 28px;
    }
  }
}
