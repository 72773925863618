.header {
  border-bottom: 1px solid #dfd7d0;
  display: flex;
}

$header-height: 95px;
$logo-height: 70px;

.logo {
  height: $logo-height;
  width: auto;
  margin:  calc(($header-height - $logo-height) / 2);
  margin-left: auto;
}

.select {
  margin: auto 30px auto auto;
}
