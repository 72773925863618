ul.ul {
  padding-left: 24px;

  li {
    list-style-image: url('../svg/ListMarker.svg');

    font-size: 16px;
    line-height: 32px;
    padding-left: 10px;
  }

  li + li {
    margin-top: 20px;
  }
}
