.collapse {
  background-color: transparent;
  margin-bottom: 24px;
}

.collapsePanel {
  padding-top: 20px;
  padding-bottom: 20px;
}

.collapsePanelHeader {
  &,
  &Active {
    color: #2a2c30;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
  }
}

.warningMessage {
  font-style: italic;
  margin-bottom: 10px;
}