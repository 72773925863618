.companyNameContainer {
  margin-top: auto;
}

.uploadLogoContainer {
  border: 1px solid #DEDAD4;
  padding: 8px;
  text-align: center;
}

.plusIcon {
  color: #7A6855;
  font-size: 16px;
  margin-left: 15px;
}

.dateRowContainer {
  background-color: #F2F2F1;
  padding: 10px;
  margin-bottom: 15px;
}

.flagIcon {
  margin-right: 5px;
  margin-bottom: 2px;
}

.noMargin {
  margin-bottom: unset;
}

.trashIconContainer {
  display: flex;
}

.clickableIcon {
  font-size: 24px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  margin-top: 10px;
}

p.tip {
  font-weight: 300;
  font-size: 14px;
}

.formDivider {
  background-color: #CFB07B;
  margin-top: 40px;
  margin-bottom: 40px;
}

.formSubTitle {
  color: black;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}