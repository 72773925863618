/*.infoContainer {
  width: 380px;
  color: #746250;
  background-color: #f1eeec;
  padding: 40px 32px 32px 32px;
  margin-bottom: 40px;
}*/

.minInvestItem {
  margin-left: 55px;
}
.minInvestItem::before {
  content: 'entre';
  position: absolute;
  left: -40px;
  top: 65px;
}

.maxInvestItem {
  margin-left: 55px;
}
.maxInvestItem::before {
  content: 'et';
  position: absolute;
  left: -40px;
  top: 65px;
}

/*
// S - XS
@media (max-width: 768px) {
  .infoContainer {
    width: 100%;
  }
}
*/
