@menu-highlight-color: @secondary-color;
@menu-item-color: #2a2c30;
@menu-item-border-bottom-width: 5px;

@transparent-border-bottom: @menu-item-border-bottom-width solid transparent;
@selected-border: @menu-item-border-bottom-width solid @menu-highlight-color;

.ant-menu-horizontal {
  .ant-menu-overflowed-submenu {
    border-bottom: @transparent-border-bottom !important;
    float: right;
  }

  // remove Menu.Item border when hover by setting the class 'not-menu-item'
  &:not(.ant-menu-dark) {
    > .ant-menu-item,
    .ant-menu-submenu {
      border-bottom: 0px !important;
      margin-top: 5px;
      padding-top: 0px;
    }

    > .ant-menu-item,
    .ant-menu-submenu {
      &:hover,
      &-selected,
      &-active,
      &-open {
        border-bottom: 0px !important;
      }
    }

    > .ant-menu-submenu {
      &:hover,
      &-active,
      &-open,
      &-selected {
        font-weight: normal !important;
      }
    }

    > .ant-menu-item::after,
    .ant-menu-submenu::after {
      border-bottom: 5px solid transparent;
    }

    > .ant-menu-item:hover::after,
    .ant-menu-submenu:hover::after,
    .ant-menu-item-selected::after {
      border-bottom: 5px solid #cfb07b;
    }

    > .not-menu-item:hover::after {
      border-bottom: 5px solid transparent;
    }

    > .ant-menu-item,
    > .ant-menu-submenu {
      color: @menu-item-color;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      border-bottom: @transparent-border-bottom;

      &:hover,
      &-active,
      &-open,
      &-selected {
        color: @menu-item-color;
        border-bottom: @selected-border;

        a {
          color: @menu-item-color;
        }
      }

      &-selected {
        font-weight: 600;
      }
    }
  }

  .ant-menu-item-active.not-menu-item {
    border-bottom: 5px solid transparent;
    cursor: unset;
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: unset;
  border-left: @selected-border;
}
