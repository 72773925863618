.TitleBloc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;

  & + & {
    margin-top: 16px;
  }
}

.marginTop {
  margin-top: 15px;
}