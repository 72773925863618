@mixin spacing($overlaps, $padding-v: 2 * $overlaps) {
  .titleContainer {
    padding: $padding-v 16px $padding-v + $overlaps;
  }

  .content {
    margin: auto $padding-v auto (-$overlaps);
  }

}

// default include
@include spacing(80px);

.coverImage {
  background-image: linear-gradient(
                  180deg,
                  rgba(0, 0, 0, 0.7) 0%,
                  rgba(0, 0, 0, 0.2) 100%
  ),
  url('/images/login.jpeg');
  background-size: cover;
  background-position: center;
}

.titleContainer {
  max-width: 68%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 10%;
}

@media(min-width: 992px) and (max-width: 1400px) {
  .titleContainer {
    max-width: 58%;
    margin-right: 35%;
    padding-right: unset;
  }
}

@media(max-width: 992px) {
  .titleContainer {
    padding-right: unset;
  }
}

.title {
  color: white;
  font-size: 24px;
  letter-spacing: 6px;
  line-height: 36px;
  text-align: center;
}

.content {
  background-color: #f1eeec;
}

.footer {
  padding-top: 40px;
  padding-bottom: 40px;

  .footerLogo {
    height: 100px;
  }
}

// sm
@media (min-width: 576px) and (max-width: 767.98px) {
  @include spacing(80px, 100px);
}

// xs
@media (max-width: 575.98px) {
  @include spacing(80px, 60px);

  .title {
    font-size: 20px;
    line-height: 35px;
  }
  .content {
    padding: 40px 60px;
  }
}


$header-height: 95px;
$logo-height: 70px;

.logo {
  height: $logo-height;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-bottom: 40px;
}

.smallLogo {
  height: 60px;
  width: auto;
  margin:  calc(($header-height - $logo-height) / 2);
}

.select {
  margin: auto 30px auto auto;
}

@media(max-width: 576px) {
  .select {
    margin: auto 30px auto 10px
  }
}

.container {
  margin-top: 80px;
  height: 100%;
}

.loginModal {
  position: absolute;
  top: 130px;
  right: 130px;
  z-index: 1;
  background-color: #F0EEEC;
}

@media(max-width: 1300px) {
  .loginModal {
    top: 150px;
  }
}

@media(min-width: 1800px) {
  .loginModal {
    top: 115px;
  }
}

.contactUsRow {
  max-height: 660px;
}

.contactUsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10%;
}

.contactUsText {
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
}

.presentationContainer {
  padding: 30px 5%;

  .categoryTitle {
    font-size: 24px;
    line-height: 28px;
    color: #7A6855;
  }

  .centeredContent {
    display: flex;
    align-items: center;
  }

  .presentationRow {
    height: 85px;
  }
}

.keyNumber1Container, .keyNumber2Container, .keyNumber3Container, .keyNumber4Container {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  color: white;
}

.keyNumber1Container, .keyNumber4Container {
  height: 480px;
}

.keyNumber2Container, .keyNumber3Container {
  height: 240px;
}

.keyNumber1Container {
  background-color: #CFB07B;
}

.keyNumber2Container {
  background-color: #F2F2F1;
  color: #516069;
}

.keyNumber3Container {
  background-color: #516069;
}

.keyNumber4Container {
  background-color: #7A6855;
}

.keyNumber1, .keyNumber2, .keyNumber3, .keyNumber4 {
  font-weight: 700;
  font-size: 78px;
  line-height: 93px;
}

.details {
  padding: 10px;
  color: #516069;
  font-size: 12px;
}

.dispositivePresentation {
  padding-top: 35px;
  padding-bottom: 35px;

  .divider {
    min-width: unset!important;
    width: 37%!important;
  }
}

.subtitle {
  font-size: 30px;
  line-height: 36px;
  padding-right: 10%;
  padding-left: 10%;
}