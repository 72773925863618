@btn-primary-color: white;
@btn-font-weight: 600;
@btn-padding-horizontal-base: 36px;
@btn-padding-vertical-base: 17px;

.ant-btn {
  &:not(.ant-input-search-button) {
    text-transform: uppercase;
    height: 50px;
    padding: @btn-padding-vertical-base @btn-padding-horizontal-base;
    letter-spacing: 3px;
    line-height: 17px;
    font-size: 12px;
  }

  &.ant-btn-link {
    padding-left: unset;
    padding-right: unset;
  }

  &.ant-btn-sm {
    height: 24px;
    padding: 0 7px;
    font-size: 10px;
    line-height: 22px;
  }

  &.add-btn {
    height: 65px;
    color: #7A6855;
  }
}

.ant-btn-link {
  span {
    border-bottom: 3px solid;
    padding-bottom: 2px;
  }
}

.main-btn {
  // using mixin antd to define a clean colored button
  .button-variant-primary(white, @secondary-color);
}

.main-btn-grey {
  .button-variant-primary(white, @grey-color);
}

.main-btn-framboise {
  .button-variant-primary(white, @framboise-color);
}

@media (max-width: @screen-sm) {
  .main-btn {
    width: 100%;
  }
}

// fix link btn with MainButton
a.ant-btn.main-btn {
  padding-top: 17px;
  line-height: 17px;
}
