@modal-close-color: @secondary-color;
@modal-footer-border-width: 0;
// hack to get full padding control
@modal-footer-padding-vertical: 0 24px;
@modal-footer-padding-horizontal: 60px 24px;
@modal-body-padding: 80px;
.ant-modal {
  &-body {
    text-align: center;
  }

  &-footer {
    text-align: center;

    button + button {
      margin-left: 16px;
    }
  }
}

// xs
@media (max-width: 575.98px) {
  .ant-modal-body {
    padding: 40px;
  }
}
