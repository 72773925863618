.grid {
  height: 100%;
}

.exportButton {
  margin-bottom: 15px;
  display: flex;
  margin-left: auto;
}

.mask {
  padding-bottom: 0;
  padding-top: 0;
}

.marginBottom {
  margin-bottom: 15px;
}

.marginTop {
  margin-top: 20px;
}