$primary-color: #746250;
$secondary-color: #cfb07b;

.datesContainerDesktop, .datesContainerMobile {
  display: flex;
  margin-bottom: 15px;
}

.datesContainerDesktop {
  flex-direction: row;
}

.datesContainerMobile {
  flex-direction: column;
}

.categoryTitle {
  color: $primary-color;
  font-size: 22px;
  display: flex;
  align-self: center;
  margin-right: 20px;
}

.startupActivityContainer {
  border: 1px solid #DEDAD4;
  padding: 20px;
}

.startupActivityContainerMobile {
  box-shadow: 0px 4px 12px 0px #0000000D;
}

.logoDesktop {
  width: 100px;
}

.logoContainer {
  margin-bottom: auto;
  margin-top: auto;
}

.logoMobile {
  width: 60px;
  margin-right: 5px;
}

.centeredContent {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.badgeEventStatus {
  color: white;
  padding: 16px 9px 16px 16px;
  text-align: center;

  .badgeIcon {
    margin-left: 7px;
    margin-bottom: 3px;
  }
}

.buttonMobile {
  width: fit-content;
  margin: 10px auto;
}

.separator:not(:last-child) {
  border-bottom: 2px solid $secondary-color;
}

.separator {
  padding: 15px;
}

.viewedStartUpsTitle {
  padding-left: 10px;
  padding-top: 10px;
}

.startupName {
  color: #746250;
  font-weight: bold;
  font-size: 16px;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.startupDate {
  font-size: 14px;
  display: flex;
  align-self: center;
}

.datesInDrawer {
  font-size: 16px;
   margin-bottom: 3px;
}
