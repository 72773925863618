$base-color: #746250;

.blockText {
  text-align: justify;
  font-size: 16px;
}

.blockIcon {
  display: inline-flex;
  margin-top: 18px;
  margin-right: 40px;
  color: $base-color;
}

.switchIcon {
  font-size: 20px;
  color: $base-color;
  padding-right: 8px;
}

.sectors {
  margin-right: 40px;
  color: $base-color;
}

.village {
  color: $base-color;
  display: flex;
}

.title {
  line-height: 36px;
}

.header {
  padding-bottom: unset;

  .headerLogo {
    display: flex;
  }
}

.blockLogo {
  background-color: white;
  padding: 24px;
}

.logo {
  width: 175px;
  height: 175px;
  object-fit: contain;
}

.blockTitle {
  align-self: flex-end;
}
