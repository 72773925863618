.ButtonCheckBox {
  flex-wrap: wrap;

  & > :global(.ant-btn) {
    margin: 0 10px 8px 0;
    text-transform: unset;
  }
}

$primary-color: #746250;
$secondary-color: #cfb07b;

.tag {
  box-sizing: border-box;
  color: $primary-color;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  padding: 15px 25px;
  margin: 0 10px 8px 0;
  cursor: pointer;
  border-color: $primary-color;

  &.disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #f5f5f5;
    cursor: default;
  }

  &.checked {
    background-color: $secondary-color;
    border-color: $secondary-color;
    color: white;
  }
}
