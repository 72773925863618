.inputNumber {
  width: 150px;

  :global(.ant-input-number-input) {
    text-align: right;
  }
}

.fullWidthInput {
  width: 100%;
}