@drawer-bg: #f1eeec;
@drawer-footer-padding-vertical: 40px;
.ant-drawer-footer {
  border-top: none;
  background-color: white;
}

@drawer-header-padding: 40px;
.ant-drawer-title {
  color: #746250;
  font-size: 20px;
  letter-spacing: 1.33px;
  line-height: 27px;
  margin-right: 70px;
}

.ant-drawer-close {
  font-size: 30px;
  padding: 40px;
}
