.text {
  margin-right: 10px;
  font-weight: bold;
}

.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.iconSelected, .iconNotSelected {
  margin-left: 5px;
  padding: 4px;
  border-radius: 4px;

}

.iconSelected {
  background-color: #746250;
}

.iconNotSelected {
  border: 1px solid #746250;
}