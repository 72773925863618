.amount {
  font-size: 28px;
}

.color {
  color: #746250;
  margin-bottom: 0;
}

.disable {
  margin-top: 30px;
}
