$lineHeight: 24px;
$logoHeight: 140px;
$logoPadding: 24px 24px 0;

.description {
  margin-top: 5px;
  color: #2a2c30;
  font-size: 16px;
  letter-spacing: 0;
  line-height: $lineHeight;
  min-height: 2 * $lineHeight;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  padding: 5px;

  .logo {
    width: 80px;
  }
}

.startupName {
  display: flex;
  align-items: center;
  color: #746250;
  font-weight: 600;
  font-size: 22px;
}

.subEcosystem {
  height: 35px;
}

.locationIcon {
  height: 25px;
}

.village {
  color: #746250;
  font-weight: bold;
  min-height: 29px;
}

:global(.ant-skeleton-element).logoLoading {
  width: 100%;

  :global(.ant-skeleton-image) {
    height: $logoHeight;
    width: 100%;
  }
}

.logoText {
  height: $logoHeight;
  padding: $logoPadding;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sectorList {
  // should be 20px, but Space-wrap has a -12px margin
  margin-bottom: 8px !important;
}
