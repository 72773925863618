.marginBottom {
  margin-bottom: 20px;
}

.noPaddingBottom {
  padding-bottom: unset;
}

.centeredContent {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.whiteContainer {
  background-color: white;
  padding: 30px;
  box-shadow: 0 4px 12px 0 #0000000D;
  border: 1px solid #F2F2F1;
  margin-top: 30px;
}

h3 {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #1C1F24;
}

.connectionEventsOverview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1200px) {
  .connectionEventsOverview {
    flex-direction: column;
    align-items: start;
  }
}

@media (max-width: 1400px) {
  .seeAllButton {
    display: none;
  }
}

.alignCenter {
  align-items: center;
}

.datePicker {
  margin-bottom: 10px;
  margin-top: 10px;
}

.nbViews {
  color: #1C1F24;
  font-size: 14px;
}