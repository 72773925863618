.datePicker {
  margin-bottom: 10px;
  margin-top: 10px;
}

.categoryTitle {
  font-weight: 600;
  font-size: 16px;
  color: #746250;
  line-height: 28px;
}

.divider {
  background-color: #CFB07B;
  margin-top: 40px;
  margin-bottom: 40px;
}

.number {
  font-weight: 400;
  font-size: 28px;
  line-height: 44px;
  color: #2A2C30;
}