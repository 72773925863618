.readBloc {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1d1f24;
}

.cguDetails {
  h1 {
    color: #746250;
    text-transform: uppercase;
  }

  b {
    font-weight: 800;
    font-size: 15px;
  }

  h2 {
    margin-top: 20px;
  }

  h3 {
    color: #000000;
    font-size: 18px;
  }
}

$header-height: 95px;
$logo-height: 70px;

.smallLogo {
  height: 60px;
  width: auto;
  margin:  calc(($header-height - $logo-height) / 2);
}

.select {
  margin: auto 30px auto auto;
}

@media(max-width: 576px) {
  .select {
    margin: auto 30px auto 10px
  }
}

.container {
  margin-top: 80px;
  height: 100%;
}