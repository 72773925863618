body {
  margin: 0;
  font-family:
    'OpenSans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'OpenSansSemiBold';
  font-style: normal;
  font-weight: 400;
  src: url('./styles/fonts/OpenSans-SemiBold.otf'); /* IE9 Compat Modes */
  src:
    local('Open Sans'),
    local('OpenSans'),
    url('./styles/fonts/OpenSans-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./styles/fonts/OpenSans-SemiBold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./styles/fonts/OpenSans-SemiBold.woff')
      format('woff'),
    /* Modern Browsers */ url('./styles/fonts/OpenSans-SemiBold.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./styles/fonts/OpenSans-SemiBold.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'OpenSansBold';
  font-style: normal;
  font-weight: 400;
  src: url('./styles/fonts/OpenSans-Bold.otf'); /* IE9 Compat Modes */
  src:
    local('Open Sans'),
    local('OpenSans'),
    url('./styles/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./styles/fonts/OpenSans-Bold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./styles/fonts/OpenSans-Bold.woff')
      format('woff'),
    /* Modern Browsers */ url('./styles/fonts/OpenSans-Bold.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('./styles/fonts/OpenSans-Bold.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}

/* alegreya-sans-regular - latin */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./styles/fonts/alegreya-sans-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src:
    local('Alegreya Sans Regular'),
    local('AlegreyaSans-Regular'),
    url('./styles/fonts/alegreya-sans-v10-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./styles/fonts/alegreya-sans-v10-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./styles/fonts/alegreya-sans-v10-latin-regular.woff') format('woff'),
    /* Modern Browsers */
      url('./styles/fonts/alegreya-sans-v10-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./styles/fonts/alegreya-sans-v10-latin-regular.svg#AlegreyaSans')
      format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  src: url('./styles/fonts/OpenSans-Regular.otf'); /* IE9 Compat Modes */
  src:
    local('Open Sans'),
    local('OpenSans'),
    url('./styles/fonts/OpenSans-Regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./styles/fonts/OpenSans-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./styles/fonts/OpenSans-Regular.woff')
      format('woff'),
    /* Modern Browsers */ url('./styles/fonts/OpenSans-Regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./styles/fonts/OpenSans-Regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}