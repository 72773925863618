.sectionContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .mainContainer {
    margin-right: 110px;
    margin-bottom: 32px;
    flex-grow: 1;
  }

  .infoContainer {
    flex: none;
    width: 300px;
  }
}

// XS and SM
@media (max-width: 768px) {
  .sectionContainer {
    flex-direction: column;

    .mainContainer {
      margin-right: 0;
    }

    .infoContainer {
      width: unset;
    }
  }
}

// MD
@media (min-width: 768px) and (max-width: 991.98px) {
  .sectionContainer {
    .infoContainer {
      width: 260px;
    }
  }
}
