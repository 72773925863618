@steps-dot-size: 18px;
@steps-current-dot-size: 18px;

.ant-steps-vertical.ant-steps-dot .ant-steps-item {
  .ant-steps-icon-dot {
    border: 3px solid #f8f6f5;
  }

  & > .ant-steps-item-container > .ant-steps-item-tail {
    left: -4px;
    top: 0;
    padding: 0;

    &::after {
      width: 2px;
      border-radius: 0;
    }
  }

  &:last-child > .ant-steps-item-container > .ant-steps-item-tail {
    display: unset;
  }

  &:first-child > .ant-steps-item-container {
    margin-top: 32px;
  }

  &:last-child > .ant-steps-item-container {
    margin-bottom: 32px;
  }

  & > .ant-steps-item-container {
    margin-top: 20px;
  }
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  min-height: unset;
}

.ant-steps-item-title {
  font-weight: 700;
  font-size: 18px;
}

.ant-steps-item-description {
  font-size: 16px;
  font-weight: 400;
}