$light: white;
$dark: #f1eeec;
$height-mixed: 70px;

.content {
  padding: 40px 20px;

  & + & {
    padding-top: 8px;
  }

  &.light {
    background-color: $light;
  }

  &.dark {
    background-color: $dark;
  }

  &.mixed {
    background: linear-gradient($dark $height-mixed, $light $height-mixed);
  }
}

// size xs
@media (max-width: 575.98px) {
  .content {
    padding: 24px;

    & + & {
      padding-top: 0;
    }
  }
}
